import React from 'react'
import './App.css'
import useTranslations from './hooks/useTranslations'

function App() {
  const t = useTranslations('main')
  return (
    <div className="App">
      <h1>{t.title}</h1>
      <h2>{t.subtitle}</h2>
      <ul>
        {t.skills.map(skill => (
          <li key={skill}>{skill}</li>
        ))}
      </ul>
      <form name="contact" method="post">
        <h3>{t.contact.title}</h3>
        <input name="name" type="text" placeholder="Your name" />
        <input name="email" type="email" placeholder="Your email" />
        <textarea name="message" placeholder="Your message" />
        <div className="align-right">
          <input type="submit" value="Submit" />
        </div>
        <input type="hidden" name="form-name" value="contact" />
      </form>
    </div>
  )
}

export default App
